/** ******************** USER ROLES PART ***********************/
// super admin role
export const ROLE_ADMIN = "ROLE_ADMIN"

// roles for users in the hospital side
export const ROLE_HOSPITAL_ADMIN = "ROLE_HOSPITAL_ADMIN"
export const ROLE_NURSE = "ROLE_NURSE"
export const INSTITUTION_ROLES = [
  ROLE_HOSPITAL_ADMIN,
  ROLE_NURSE
]

// roles for users in the school side
export const ROLE_ENCODER = "ROLE_ENCODER"
export const ROLE_COORDINATOR = "ROLE_COORDINATOR"
export const ROLE_SUPERVISOR = "ROLE_SUPERVISOR"
export const ROLE_VALIDATOR = "ROLE_VALIDATOR"
export const ROLE_STUDENT = "ROLE_STUDENT"

export const SCHOOL_ROLES = [
  ROLE_ENCODER,
  ROLE_COORDINATOR,
  ROLE_SUPERVISOR,
  ROLE_VALIDATOR
]

/** ******************** MOMENT PART ***********************/
export const DATE_FORMAT = "DD/MM/YYYY"
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss"
export const DATE_TIME_FORMAT_WITHOUT_SECONDS = "DD/MM/YYYY HH:mm"
