import {useState} from "react"

import {Button, Card, Divider, Layout, Switch, Tag} from "antd"
import Title from "antd/es/typography/Title"
import Search from "antd/es/input/Search"
import BackOfficeTable from "../shared/BackOfficeTable"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faSchool} from "@fortawesome/pro-light-svg-icons"
import TableActionsButtons from "../shared/TableActionsButtons"
import EditSchoolModal from "@components/app/schools/EditSchoolModal"
// @ts-ignore
import NewSchoolModal from "./NewSchoolModal.tsx"
import {LINKED_INSTITUTION_PATH, OPTIONS_PATH, SECTION_PATH} from "../../../routes"

const SchoolsView = () => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [openModalNewSchool, setOpenModalNewSchool] = useState(false)

  const handleOk = () => {
    setSelectedRow(null)
  }

  const handleCancel = () => {
    setSelectedRow(null)
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => (status ? (<Tag color="success">Managed</Tag>) : (
        <Tag color="error">Unmanaged</Tag>))
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      key: "deleted",
      render: (deleted: boolean) => (deleted ? (<Tag color="error">Deleted</Tag>) : (<Tag color="success">Active</Tag>))
    },
    {
      title: "Daily connections",
      dataIndex: "daily",
      key: "daily"
    },
    {
      title: "Weelky connections",
      dataIndex: "weekly",
      key: "weekly"
    },
    {
      title: "Monthly connections",
      dataIndex: "monthly",
      key: "monthly"
    },
    {
      title: "Quarterly connections",
      dataIndex: "quarterly",
      key: "quarterly"
    },
    {
      title: "Encoded internships",
      dataIndex: "internships",
      key: "internships"
    },
    {
      title: "Students",
      dataIndex: "students",
      key: "students"
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <TableActionsButtons
          onEditClick={() => setSelectedRow(record)}
          deleted={record.deleted}
          items={[
            {
              key: 1,
              label: (<Link to={LINKED_INSTITUTION_PATH}>Linked institutions</Link>)
            },
            {
              key: 2,
              label: (<Link to={OPTIONS_PATH}>Options</Link>)
            },
            {
              key: 3,
              label: (<Link to={SECTION_PATH}>Sections</Link>)
            }
          ]}/>)
    }
  ]

  const dataSource = [
    {
      key: 1,
      id: 654,
      name: "HELHa",
      status: false,
      deleted: false,
      daily: 92,
      weekly: 467,
      monthly: 1029,
      quarterly: 3575,
      internships: 243,
      students: 765
    },
    {
      key: 2,
      id: 676,
      name: "UCLouvain",
      status: true,
      deleted: false,
      daily: 198,
      weekly: 345,
      monthly: 987,
      quarterly: 3456,
      internships: 234,
      students: 877
    },
    {
      key: 3,
      id: 678,
      name: "Condorcet",
      status: true,
      deleted: true,
      daily: 456,
      weekly: 876,
      monthly: 1234,
      quarterly: 9876,
      internships: 568,
      students: 987
    },
    {
      key: 4,
      id: 679,
      name: "ULB",
      status: true,
      deleted: false,
      daily: 234,
      weekly: 567,
      monthly: 1234,
      quarterly: 3456,
      internships: 234,
      students: 876
    }
  ]

  return (
    <Layout className="contentLayout">

      <EditSchoolModal data={selectedRow} onClose={handleCancel} onEdit={handleOk}/>

      <NewSchoolModal open={openModalNewSchool} setOpen={setOpenModalNewSchool}/>

      <Card className="contentCard">
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Title className="pageTitleText" style={{color: "rgb(77,77,77)"}}>
            <FontAwesomeIcon icon={faSchool} style={{marginRight: 10}}/>
            Schools
          </Title>
          <Button onClick={() => {
            setOpenModalNewSchool(true)
          }} type="primary" shape="round" className="success">
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}}/>
            Create new school
          </Button>
        </div>
        <Divider/>

        <Card style={{margin: 10}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>
              <span style={{fontSize: 13, margin: 10}}>Show deleted schools : </span>
              <Switch checkedChildren="True" unCheckedChildren="False"/>
            </div>
            <div>
              <Search placeholder="Search school" onSearch={(value: string) => {
                alert("you searched : " + value)
              }} style={{width: 200}}/>
            </div>
          </div>
          <Divider/>
          <BackOfficeTable columns={columns} dataSource={dataSource}/>
        </Card>
      </Card>
    </Layout>
  )
}

export default SchoolsView
