import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import {Button, Card, Divider, Layout, Switch, Tag} from "antd"
import Title from "antd/es/typography/Title"
import Search from "antd/es/input/Search"
import BackOfficeTable from "../shared/BackOfficeTable"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHospital, faPlus} from "@fortawesome/pro-light-svg-icons"
import institutionsDumpData from "@components/app/institutions/InstitutionsDumpData"
import TableActionsButtons from "../shared/TableActionsButtons"
// @ts-ignore
import NewInstitutionModal from "./NewInstitutionModal.tsx"
// @ts-ignore
import EditInstitutionModal from "./EditInstitutionModal.tsx"
import {LINKED_SCHOOL_PATH, OPTIONS_PATH, SECTORS_PATH} from "../../../routes"


const getColumns = (setSelectedRow: Dispatch<SetStateAction<null>>): any => {
  return [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => (status ? (<Tag color="success">Managed</Tag>) : (
        <Tag color="error">Unmanaged</Tag>))
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      key: "deleted",
      render: (deleted: boolean) => (deleted ? (<Tag color="error">Deleted</Tag>) : (<Tag color="green">Active</Tag>))
    },
    {
      title: "Daily connections",
      dataIndex: "daily",
      key: "daily"
    },
    {
      title: "Weelky connections",
      dataIndex: "weekly",
      key: "weekly"
    },
    {
      title: "Monthly connections",
      dataIndex: "monthly",
      key: "monthly"
    },
    {
      title: "Quarterly connections",
      dataIndex: "Quarterly",
      key: "quarterly"
    },
    {
      title: "Encoded internships",
      dataIndex: "internships",
      key: "internships"
    },
    {
      title: "Students",
      dataIndex: "students",
      key: "students"
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <TableActionsButtons
          items={[
            {
              key: 1,
              label: (<Link to={LINKED_SCHOOL_PATH}>Linked school</Link>)
            },
            {
              key: 2,
              label: (<Link to={OPTIONS_PATH}>Options</Link>)
            },
            {
              key: 3,
              label: (<Link to={SECTORS_PATH}>Sectors</Link>)
            }
          ]}
          deleted={record.deleted}
          onEditClick={() => setSelectedRow(record)}
        />
      )
    }
  ]
}

const InstitutionsView = () => {

  const [selectedRow, setSelectedRow] = useState(null)
  const [openModalNewInstitution, setOpenModalNewInstitution] = useState(false)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns(getColumns(setSelectedRow))
  }, [])

  const handleOk = () => {
    setSelectedRow(null)
  }

  const handleCancel = () => {
    setSelectedRow(null)
  }

  return (
    <Layout className="contentLayout">

      <EditInstitutionModal data={selectedRow} onEdit={handleOk} onClose={handleCancel}/>

      <NewInstitutionModal open={openModalNewInstitution} setOpen={setOpenModalNewInstitution}/>

      <Card className="contentCard">
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Title className="pageTitleText" style={{color: "rgb(77,77,77)"}}>
            <FontAwesomeIcon icon={faHospital} style={{marginRight: 10}}/>
            Institutions
          </Title>
          <Button onClick={() => {
            setOpenModalNewInstitution(true)
          }} shape="round" className="success">
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}}/>
            Create new institution
          </Button>
        </div>
        <Divider/>

        <Card style={{margin: 10}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>
              <span style={{fontSize: 13, margin: 10}}>Show deleted institutions : </span>
              <Switch checkedChildren="True" unCheckedChildren="False"/>
            </div>
            <div>
              <Search placeholder="Search institutions" onSearch={(value: string) => {
                alert("you searched : " + value)
              }} style={{width: 200}}/>
            </div>
          </div>
          <Divider/>

          <BackOfficeTable columns={columns} dataSource={institutionsDumpData}/>
        </Card>
      </Card>
    </Layout>
  )
}

export default InstitutionsView