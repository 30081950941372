import {Input, Modal} from "antd"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus} from "@fortawesome/pro-light-svg-icons"

interface CProps {
  open: boolean,
  setOpen: (open: boolean) => void
}

const NewInstitutionModal = ({open, setOpen}: CProps) => {

  const handleOk = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon success" style={{margin: 10, marginLeft: 15}}><FontAwesomeIcon color="white"
                                                                                                    size="xl"
                                                                                                    icon={faPlus}
                                                                                                    style={{margin: 10}}/>
          </div>
          <span>Create new institution : </span>
        </div>
      )}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{className: "success"}}
    >
      <label htmlFor="institutionName">Institution name</label>
      <Input
        id="institutionName"
        placeholder="Institution name"
        type="text"
      />
      <label htmlFor="institutionAddress">Institution address</label>
      <Input
        id="institutionAddress"
        placeholder="Institution address"
        type="text"
      />
      <label htmlFor="institutionType">Institution type</label>
      <Input
        id="institutionType"
        placeholder="Institution type"
        type="text"
      />
    </Modal>
  )
}

export default NewInstitutionModal