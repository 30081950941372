const hostname = import.meta.env.VITE_API_URL

export const getTokens = async (username: string, password: string) => {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const body = JSON.stringify({username, password})

  const url = new URL(`${hostname}/auth`)

  return await fetch(url.toString(), {method: "POST", headers, body})
}