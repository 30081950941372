import {Input, Modal} from "antd"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus} from "@fortawesome/pro-light-svg-icons"

interface CProps {
  open: boolean,
  setOpen: (open: boolean) => void
}

const NewSchoolModal = ({open, setOpen}: CProps) => {

  const handleOk = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon success" style={{margin: 10, marginLeft: 15}}><FontAwesomeIcon color="white"
                                                                                                    size="xl"
                                                                                                    icon={faPlus}
                                                                                                    style={{margin: 10}}/>
          </div>
          <span>Create new school : </span>
        </div>
      )}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{className: "success"}}
    >
      <label htmlFor="schoolName">School name</label>
      <Input
        id="schoolName"
        placeholder="School name"
        type="text"
      />
      <label htmlFor="schoolAddress">School address</label>
      <Input
        id="schoolAddress"
        placeholder="School address"
        type="text"
      />
    </Modal>
  )
}

export default NewSchoolModal