import {RouteObject} from "react-router-dom"
import Home from "@components/Home"
import InstitutionsView from "@components/app/institutions/InstitutionsView"
import SchoolsView from "@components/app/schools/SchoolsView"
import UsersView from "@components/app/users/UsersView"

export const HOME_PATH: "/" = "/"
export const INSTITUTIONS_PATH: "/institutions" = "/institutions"
export const SCHOOLS_PATH: "/schools" = "/schools"
export const USERS_PATH: "/users" = "/users"
export const SANDBOXES_PATH: "/sandboxes" = "/sandboxes"
export const LINKED_SCHOOL_PATH: "/linkedSchool" = "/linkedSchool"
export const LINKED_INSTITUTION_PATH: "/linkedInstitutions" = "/linkedInstitutions"
export const OPTIONS_PATH: "/options" = "/options"
export const SECTORS_PATH: "/sectors" = "/sectors"
export const SECTION_PATH: "/sections" = "/sections"

const routes: RouteObject[] = [
  {
    path: HOME_PATH,
    element: <Home/>
  },
  {
    path: INSTITUTIONS_PATH,
    element: <InstitutionsView/>
  },
  {
    path: SCHOOLS_PATH,
    element: <SchoolsView/>
  },
  {
    path: USERS_PATH,
    element: <UsersView/>
  },
  {
    path: LINKED_SCHOOL_PATH,
    element: <div>TODO</div>
  },
  {
    path: LINKED_INSTITUTION_PATH,
    element: <div>TODO</div>
  },
  {
    path: OPTIONS_PATH,
    element: <div>TODO</div>
  },
  {
    path: SECTORS_PATH,
    element: <div>TODO</div>
  },
  {
    path: SECTION_PATH,
    element: <div>TODO</div>
  }
]

export default routes