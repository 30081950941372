import {Button, Card, Form, Input, Layout, notification, Spin} from "antd"
import {getTokens} from "../services/loginServices"
import {HOME_PATH} from "../routes"
import {useState} from "react"
import {ROLE_ADMIN} from "../utils/constants"

const LoginView = () => {
  const [spinning, setSpinning] = useState(false)

  const handleSubmit = (values: { "username": string, "password": string }): void => {
    setSpinning(true)
    getTokens(values.username, values.password).then(async r => {
      if (!r.ok) {
        throw new Error("Connexion failed, please check your credentials")
      }
      const {token} = await r.json()
      localStorage.setItem("token", token)
      const roles = JSON.parse(atob(token.split(".")[1])).roles
      if (!roles.includes(ROLE_ADMIN)) {
        throw new Error("You are not allowed to access this application")
      } else {
        window.location.href = HOME_PATH
      }
    }).catch(e => {
      notification.error({
        message: "Error",
        description: e.message,
        placement: "bottomRight"
      })
      setSpinning(false)
      localStorage.removeItem("token")
    })
  }

  return (
    <Layout style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Card style={{width: "auto", padding: 20}}>
        <div style={{textAlign: "center"}}>
          <img width={50} style={{marginBottom: 10}} src="./src/assets/interneo_picto.svg"/>
          <h1>Login</h1>
        </div>

        <Form onFinish={handleSubmit}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{required: true, message: "Please input your username!"}]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{required: true, message: "Please input your password!"}]}
          >
            <Input.Password/>
          </Form.Item>
          <Form.Item>
            <div style={{textAlign: "center"}}>
              <Button htmlType="submit" className="success" style={{marginTop: 10}} type="primary">Login <Spin
                style={{marginLeft: 10}} size="small" spinning={spinning}/></Button>
            </div>
          </Form.Item>

        </Form>
      </Card>
    </Layout>
  )
}

export default LoginView