import {Button, Dropdown, Popconfirm} from "antd"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye, faList, faTrash} from "@fortawesome/pro-light-svg-icons"
import {faArrowRotateRight} from "@fortawesome/pro-light-svg-icons/faArrowRotateRight"

type CProps = {
  items: any
  onEditClick: (item: any) => void
  deleted: boolean
  onDeleted?: () => void
  onRestore?: () => void
}

const TableActionsButtons = ({items = null, onEditClick, deleted = false, onDeleted, onRestore}: CProps) => {
  return (
    <div style={{display: "flex"}}>
      {items !== null && !deleted && (
        <Dropdown
          menu={{items}}
          placement="bottomLeft"
          arrow={{pointAtCenter: true}}>
          <Button
            style={{
              backgroundColor: "#A9A9AA",
              marginRight: 5,
              borderRadius: 100,
              color: "white",
              border: "none"
            }}>
            <FontAwesomeIcon icon={faList}/>
          </Button>
        </Dropdown>
      )}
      {!deleted &&
          <Button
              onClick={onEditClick}
              type="primary"
              shape="round"
              style={{backgroundColor: "#A32CCF", marginRight: 5}}>
              <FontAwesomeIcon icon={faEye}/>
          </Button>
      }
      {deleted &&
          <Popconfirm
              icon={<FontAwesomeIcon className="primary_color" icon={faArrowRotateRight}/>}
              title={`Are you sure you want to restore this item?`}
              okText="Yes"
              onConfirm={() => {
                if (onRestore) {
                  onRestore()
                }
              }}
              okButtonProps={{type: "primary", shape: "round"}}
              cancelButtonProps={{type: "dashed", shape: "round"}}
              cancelText="No"
          >
              <Button
                  type="primary"
                  shape="round"
              >
                  <FontAwesomeIcon icon={faArrowRotateRight}/>
              </Button>
          </Popconfirm>
      }
      {!deleted &&
          <Popconfirm
              icon={<FontAwesomeIcon style={{color: "red"}} icon={faTrash}/>}
              title={`Are you sure you want to delete this item?`}
              okText="Yes"
              onConfirm={() => {
                if (onDeleted) {
                  onDeleted()
                }
              }}
              okButtonProps={{type: "primary", shape: "round", style: {backgroundColor: "red"}}}
              cancelButtonProps={{type: "dashed", shape: "round"}}
              cancelText="No">
              <Button type="primary" shape="round" style={{backgroundColor: "red"}}>
                  <FontAwesomeIcon icon={faTrash}/>
              </Button>
          </Popconfirm>
      }
    </div>
  )
}

export default TableActionsButtons
