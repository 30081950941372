import {useEffect, useState} from "react"

import {Button, Divider, Form, Input, Modal, notification, Select, Spin} from "antd"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus} from "@fortawesome/pro-light-svg-icons"
import {
    ROLE_ADMIN,
    ROLE_COORDINATOR,
    ROLE_ENCODER,
    ROLE_HOSPITAL_ADMIN,
    ROLE_NURSE,
    ROLE_STUDENT,
    ROLE_SUPERVISOR,
    ROLE_VALIDATOR
} from "../../../utils/constants"
import User from "../../../models/User"
import {createUser} from "../../../services/userService"

interface CProps {
  open: boolean
  setOpen: (open: boolean) => void
  onSuccess: () => void
}

const NewUserModal = ({open, setOpen, onSuccess}: CProps) => {
  const [user, setUser] = useState(new User())
  const [spinning, setSpinning] = useState(false)

  useEffect(() => {
    if (!open) {
      setUser(new User())
    }
  }, [open])

  const handleOk = (values: User) => {
    if (!values.username) {
      values.username = values.email
    }
    if (!values.language) {
      values.language = "en"
    }
    values.roles = [values.roles as string]

    setSpinning(true)
    createUser(values).then(async r => {
      if (!r.ok) {
        throw new Error(`${r.status}: ${r.statusText}`)
      }
      onSuccess()
      setOpen(false)
      setSpinning(false)
      notification.success({
        message: "Success",
        description: `User: ${values.firstname} ${values.lastname} created`,
        placement: "bottomRight"
      })
    }).catch(e => {
      notification.error({
        message: "Error",
        description: e.message,
        placement: "bottomRight"
      })
      setSpinning(false)
    })
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon success" style={{margin: 10, marginLeft: 15}}><FontAwesomeIcon color="white"
                                                                                                    size="xl"
                                                                                                    icon={faPlus}
                                                                                                    style={{margin: 10}}/>
          </div>
          <span>Create new User : </span>
        </div>
      )}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Divider/>
      <Spin spinning={spinning}>
        <Form id="form" onFinish={handleOk}>

          <Form.Item
            label="Firstname"
            rules={[{required: true, message: "Please input a firstname!"}]}
            name="firstname"
          >
            <Input
              value={user.firstname}
              onChange={e => setUser({...user, firstname: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            label="Lastname"
            rules={[{required: true, message: "Please input a lastname!"}]}
            name="lastname"
          >
            <Input
              value={user.lastname}
              onChange={e => setUser({...user, lastname: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            rules={[{required: true, message: "Please input an email!"}]}
            name="email"
          >
            <Input
              value={user.email}
              onChange={e => setUser({...user, email: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
          >
            <Input
              value={user.username}
              onChange={e => setUser({...user, username: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="roles"
            rules={[{required: true, message: "Please input a role!"}]}
          >
            <Select
              value={user.roles}
              onChange={value => setUser({...user, roles: value})}
            >
              <Select.Option value={ROLE_STUDENT}>Student</Select.Option>
              <Select.Option value={ROLE_ADMIN}>Admin</Select.Option>
              <Select.Option value={ROLE_COORDINATOR}>Coordinator</Select.Option>
              <Select.Option value={ROLE_ENCODER}>Encoder</Select.Option>
              <Select.Option value={ROLE_SUPERVISOR}>Supervisor</Select.Option>
              <Select.Option value={ROLE_VALIDATOR}>Validator</Select.Option>
              <Select.Option value={ROLE_HOSPITAL_ADMIN}>Hospital admin</Select.Option>
              <Select.Option value={ROLE_NURSE}>Nurse</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Language"
            name="language"
          >
            <Select
              value={user.language}
              onChange={value => setUser({...user, language: value})}
            >
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="nl">Dutch</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
          >
            <Input
              value={user.phone}
              onChange={e => setUser({...user, phone: e.target.value})}
            />
          </Form.Item>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Button style={{marginRight: 10}} onClick={handleCancel}>Cancel</Button>
            <Button htmlType="submit" className="success">Create</Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  )
}

export default NewUserModal