import {useEffect, useState} from "react"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye} from "@fortawesome/pro-light-svg-icons"
import {Card, Input, Modal, Switch} from "antd"
import Institution from "../../../models/Institution"


interface CProps {
  data: Institution | null
  onClose: () => void
  onEdit: () => void
}

const EditInstitutionModal = ({data, onClose, onEdit}: CProps) => {
  const [institution, setInstitution] = useState(new Institution())

  useEffect(() => {
    if (data) {
      setInstitution(data)
    }
  }, [data])

  const handleClose = () => {
    setInstitution(new Institution())
    onClose()
  }

  return (
    <Modal
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon" style={{backgroundColor: "#A32CCF", margin: 10, marginLeft: 15}}>
            <FontAwesomeIcon color="white" size="xl" icon={faEye} style={{margin: 10}}/>
          </div>
          {data && <h2 style={{color: "#232323"}}>&nbsp {data.name} ({data.id})</h2>}
        </div>
      )}
      open={!!data}
      onOk={onEdit}
      onCancel={handleClose}
      okText="Edit"
      okButtonProps={{style: {backgroundColor: "#A32CCF", marginTop: 15}}}
    >
      <Card className="opalBackground">
        <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10, alignItems: "center"}}>
          <div style={{flexDirection: "column"}}>
            <label>Name : </label>
            <Input
              style={{width: "90%"}}
              value={institution.name}
              onChange={e => setInstitution({...institution, name: e.target.value})}
            />
          </div>
          <div style={{flexDirection: "row"}}>
            <label>Managed : </label>
            <Switch
              checked={institution.status}
              onChange={e => setInstitution({...institution, status: e.valueOf()})}
            />
          </div>
        </div>
        <div style={{flexDirection: "row"}}>
          <label htmlFor="acronym">Acronym:</label>
          <Input
            type="text"
            id="acronym"
            name="acronym"
            value={institution.acronym}
            onChange={e => setInstitution({...institution, acronym: e.target.value})}
          /><br/>

          <label htmlFor="address">Address:</label>
          <Input
            type="text"
            id="address"
            name="address"
            value={institution.address ?? ""}
            onChange={e => setInstitution({...institution, address: e.target.value})}
          /><br/>

          <label htmlFor="zipcode">Zip Code:</label>
          <Input
            type="text"
            id="zipcode"
            name="zipcode"
            value={institution.zipCode ?? ""}
            onChange={e => setInstitution({...institution, zipCode: e.target.value})}
          /><br/>

          <label htmlFor="city">City:</label>
          <Input
            type="text"
            id="city"
            name="city"
            value={institution.city ?? ""}
            onChange={e => setInstitution({...institution, city: e.target.value})}
          /><br/>

          <label htmlFor="slug">Slug:</label>
          <Input
            type="text"
            id="slug"
            name="slug"
            value={institution.slug ?? ""}
            onChange={e => setInstitution({...institution, slug: e.target.value})}
          /><br/>

          <label htmlFor="country">Country:</label>
          <Input
            type="text"
            id="country"
            name="country"
            value={institution.country ?? ""}
            onChange={e => setInstitution({...institution, country: e.target.value})}
          /><br/>
        </div>
      </Card>
    </Modal>
  )
}

export default EditInstitutionModal

