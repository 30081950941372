export default class Institution {
  key?: number
  id?: number
  name?: string
  acronym: string = ""
  address?: string | null
  zipCode?: string | null
  city?: string | null
  slug?: string | null
  country?: string | null
  contact?: [] = []
  sector?: [] = []
  status?: boolean
  deleted?: boolean
  students?: number
  options?: [] = []
  internships?: []
}


