import {Tag} from "antd"
import {
    ROLE_ADMIN,
    ROLE_COORDINATOR,
    ROLE_ENCODER,
    ROLE_HOSPITAL_ADMIN,
    ROLE_NURSE,
    ROLE_STUDENT,
    ROLE_SUPERVISOR,
    ROLE_VALIDATOR
} from "../../../utils/constants"

interface CProps {
  role?: string
}

const UserRoleTag = ({role}: CProps) => {
  switch (role) {
    case ROLE_ADMIN:
      return (<Tag color={"red"}>Admin</Tag>)
    case ROLE_COORDINATOR:
      return (<Tag color={"blue"}>Coordinator</Tag>)
    case ROLE_ENCODER:
      return (<Tag color={"default"}>Encoder</Tag>)
    case ROLE_SUPERVISOR:
      return (<Tag color={"green"}>Supervisor</Tag>)
    case ROLE_VALIDATOR:
      return (<Tag color={"purple"}>Validator</Tag>)
    case ROLE_HOSPITAL_ADMIN:
      return (<Tag color={"orange"}>Hospital Admin</Tag>)
    case ROLE_NURSE:
      return (<Tag color={"cyan"}>Nurse</Tag>)
    case ROLE_STUDENT:
      return (<Tag color={"warning"}>Student</Tag>)
    default:
      return (<Tag>Other</Tag>)
  }
}

export default UserRoleTag