import User from "../models/User"

export const hostname = import.meta.env.VITE_API_URL
const token = localStorage.getItem("token")

export const getUsers = async (page: number = 1, deleted?: boolean, filter?: string, pageSize?: number) => {
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)

  const params = new URLSearchParams()
  params.append("page", page.toString())

  if (deleted) {
    params.append("deleted", String(1))
  }

  if (filter) {
    params.append("filter", filter)
  }

  if (pageSize) {
    params.append("per_page", pageSize.toString())
  }

  const url = new URL(`${hostname}/api/users`)
  url.search = params.toString()

  return await fetch(url.toString(), {headers})
}

export const deleteUser = async (id: number) => {
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)

  const url = new URL(`${hostname}/api/user/${id}`)

  return await fetch(url.toString(), {
    method: "DELETE",
    headers
  })
}

export const restoreUser = async (id: number) => {
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)

  const url = new URL(`${hostname}/user/${id}/restore`)

  return await fetch(url.toString(), {
    method: "PATCH",
    headers
  })
}

export const createUser = async (user: User) => {
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)
  headers.append("Content-Type", "application/json")

  const url = new URL(`${hostname}/user/create`)

  return await fetch(url.toString(), {
    method: "POST",
    headers,
    body: JSON.stringify(user)
  })
}

export const updateUser = async (user: User) => {
  user.updatedBy = undefined
  delete user.key
  console.log(user)
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)
  headers.append("Content-Type", "application/json")

  const url = new URL(`${hostname}/api/user/${user.id}`)

  return await fetch(url.toString(), {
    method: "PATCH",
    headers,
    body: JSON.stringify(user)
  })
}