export default class User {
  key?: number
  id!: number
  email!: string
  username!: string
  firstname?: string
  lastname?: string
  roles!: string | string[]
  language?: string
  phone?: string
  deleted!: boolean
  lastLogin?: string
  createdAt?: string
  createdBy?: User
  updatedAt?: string
  updatedBy?: User
}