import {useState} from "react"

import type {MenuProps} from "antd"
import {Avatar, Button, Divider, Layout, Menu, Tooltip} from "antd"
import {Link, Outlet} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHospital, faHouse, faRightFromBracket, faSchool, faUser, faUsers} from "@fortawesome/pro-light-svg-icons"
import {HOME_PATH, INSTITUTIONS_PATH, SCHOOLS_PATH, USERS_PATH} from "../routes"
import interneoLogo from "../assets/interneo_picto.svg"

const {Sider} = Layout

type MenuItem = Required<MenuProps>["items"][number]

const items: MenuItem[] = [
  {
    key: 1,
    label: "Home",
    icon: <Link to={HOME_PATH}><FontAwesomeIcon icon={faHouse}/></Link>
  }, {
    key: 4,
    label: "Users",
    icon: <Link to={USERS_PATH}><FontAwesomeIcon icon={faUsers}/></Link>
  }, {
    key: 2,
    label: "Institutions",
    icon: <Link hidden to={INSTITUTIONS_PATH}><FontAwesomeIcon icon={faHospital}/></Link>,
    disabled: true
  }, {
    key: 3,
    label: "Schools",
    icon: <Link hidden to={SCHOOLS_PATH}><FontAwesomeIcon icon={faSchool}/></Link>,
    disabled: true
  }
]

const Navbar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <Layout style={{minHeight: "100vh"}}>
      <Sider style={{zIndex: 2}} width={250} theme={"light"} collapsible collapsed={collapsed}
             onCollapse={(value) => setCollapsed(value)}>
        <Link to={"/"} style={{display: "flex", justifyContent: "center", padding: 10}}>
          <img width={40} src={interneoLogo} alt="Logo Interneo"/>
          <span hidden={collapsed} style={{color: "grey", padding: 15, fontSize: 25}}>Back-Officeo</span>
        </Link>
        <Menu style={{padding: 10}} defaultSelectedKeys={["1"]} mode="inline" items={items}/>
      </Sider>
      {/*HEADER*/}
      <div style={{width: "100%", overflow: "auto"}}>
        <div style={{
          height: "6vh",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: 20
        }}>
          <Avatar style={{marginRight: 5}} icon={<FontAwesomeIcon icon={faUser}/>}/>
          <span style={{
            marginRight: 5,
            fontWeight: "bold",
            fontSize: "120%"
          }}> {JSON.parse(atob(localStorage.getItem("token")!.split(".")[1])).username}</span>
          <Divider type="vertical"/>
          <Tooltip title="Logout" placement="bottomRight">
            <Button
              onClick={() => {
                localStorage.removeItem("token")
                window.location.href = "/"
              }}
              className={"danger"}
              shape="circle"
              danger
              type={"primary"}
              icon={<FontAwesomeIcon size={"lg"} icon={faRightFromBracket}/>}
            />
          </Tooltip>
        </div>
        <Outlet/>
      </div>
    </Layout>
  )
}

export default Navbar
