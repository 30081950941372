import {Card, Divider, Layout} from "antd"
import Title from "antd/es/typography/Title"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHome, faHospital, faSchool, faUsers} from "@fortawesome/pro-light-svg-icons"
import {INSTITUTIONS_PATH, SCHOOLS_PATH, USERS_PATH} from "../routes"

const Home = () => {
  return (
    <Layout className="contentLayout">
      <Card className="contentCard">
        <Title className="pageTitleText" style={{color: "rgb(77,77,77)"}}>
          <FontAwesomeIcon icon={faHome} style={{marginRight: 10}}/>
          Home Page
        </Title>
        <Divider/>

        <div className="homeContent"
             style={{height: "60vh", display: "flex", justifyContent: "space-around", alignItems: "center"}}>
          <Link className="homeLink" to={INSTITUTIONS_PATH}><Card
            className="animate__animated animate__zoomIn homeCard"><FontAwesomeIcon icon={faHospital} size="3x"/>
            <p>Institutions</p></Card></Link>
          <Link className="homeLink" to={SCHOOLS_PATH}><Card
            className="animate__animated animate__zoomIn homeCard"><FontAwesomeIcon icon={faSchool} size="3x"/>
            <p>Schools</p></Card></Link>
          <Link className="homeLink" to={USERS_PATH}><Card
            className="animate__animated animate__zoomIn homeCard"><FontAwesomeIcon icon={faUsers} size="3x"/>
            <p>Users</p></Card></Link>
        </div>
      </Card>
    </Layout>
  )
}

export default Home