const institutionsDumpData: object = [
  {
    key: 1,
    id: 1,
    name: "Institution St. Joseph",
    status: true,
    deleted: false,
    daily: 92,
    weekly: 467,
    monthly: 1029,
    quarterly: 3575,
    internships: 243,
    students: 765,
    acronym: "STJ",
    zipCode: "75000",
    city: "Paris",
    slug: "st-joseph",
    country: "France",
    contact: [
      {
        id: 1,
        name: "John Doe",
        email: "johndoe@mail.fr"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      }
    ]
  },
  {
    key: 2,
    id: 2,
    name: "Institution Marie Curie",
    status: true,
    deleted: false,
    daily: 124,
    weekly: 620,
    monthly: 1365,
    quarterly: 4750,
    internships: 341,
    students: 1065,
    acronym: "IMC",
    zipCode: "69000",
    city: "Lyon",
    slug: "marie-curie",
    country: "France",
    contact: [
      {
        id: 2,
        name: "Jane Smith",
        email: "janesmith@mail.fr"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      },
      {
        id: 2,
        name: "Option 2"
      }
    ]
  },
  {
    key: 3,
    id: 3,
    name: "Hospital San Juan de Dios",
    status: true,
    deleted: false,
    daily: 150,
    weekly: 750,
    monthly: 1650,
    quarterly: 5750,
    internships: 400,
    students: 1250,
    acronym: "HSJD",
    zipCode: "08001",
    city: "Barcelona",
    slug: "san-juan-de-dios",
    country: "Spain",
    contact: [
      {
        id: 3,
        name: "Juan Pérez",
        email: "juanperez@mail.es"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      },
      {
        id: 2,
        name: "Option 2"
      },
      {
        id: 3,
        name: "Option 3"
      }
    ]
  },
  {
    key: 8,
    id: 928,
    name: "Hopital Saint-Luc",
    status: true,
    deleted: true,
    daily: 104,
    weekly: 520,
    monthly: 1144,
    quarterly: 3976,
    internships: 260,
    students: 820,
    acronym: "HSL",
    zipCode: "1000",
    city: "Bruxelles",
    slug: "saint-luc",
    country: "Belgium",
    contact: [
      {
        id: 4,
        name: "Sophie Martin",
        email: "sophiemartin@mail.be"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 2,
        name: "Option 2"
      },
      {
        id: 3,
        name: "Option 3"
      }
    ]
  },
  {
    key: 9,
    id: 305,
    name: "General Hospital of Berlin",
    status: true,
    deleted: false,
    daily: 142,
    weekly: 710,
    monthly: 1562,
    quarterly: 5420,
    internships: 390,
    students: 1220,
    acronym: "GHB",
    zipCode: "10117",
    city: "Berlin",
    slug: "general-hospital-berlin",
    country: "Germany",
    contact: [
      {
        id: 5,
        name: "Hans Schmidt",
        email: "hansschmidt@mail.de"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      },
      {
        id: 3,
        name: "Option 3"
      }
    ]
  },
  {
    key: 10,
    id: 417,
    name: "Institute of Neurology and Neurosurgery",
    status: true,
    deleted: false,
    daily: 98,
    weekly: 490,
    monthly: 1078,
    quarterly: 3746,
    internships: 185,
    students: 580,
    acronym: "INN",
    zipCode: "08034",
    city: "Barcelona",
    slug: "neurology-neurosurgery",
    country: "Spain",
    contact: [
      {
        id: 6,
        name: "Carla Lopez",
        email: "carlalopez@mail.es"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      },
      {
        id: 2,
        name: "Option 2"
      },
      {
        id: 3,
        name: "Option 3"
      }
    ]
  },
  {
    key: 55,
    id: 1,
    name: "Institution St. Joseph",
    status: true,
    deleted: false,
    daily: 92,
    weekly: 467,
    monthly: 1029,
    quarterly: 3575,
    internships: 243,
    students: 765,
    acronym: "STJ",
    zipCode: "75000",
    city: "Paris",
    slug: "st-joseph",
    country: "France",
    contact: [
      {
        id: 1,
        name: "John Doe",
        email: "johndoe@mail.fr"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 1,
        name: "Option 1"
      }
    ]
  },
  {
    key: 88,
    id: 2,
    name: "Hospital de la Salud",
    status: true,
    deleted: true,
    daily: 53,
    weekly: 267,
    monthly: 587,
    quarterly: 2036,
    internships: 120,
    students: 375,
    acronym: "HS",
    zipCode: "46001",
    city: "Valencia",
    slug: "hospital-salud",
    country: "Spain",
    contact: [
      {
        id: 2,
        name: "Maria Gomez",
        email: "mariagomez@mail.es"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 2,
        name: "Option 2"
      }
    ]
  },
  {
    key: 67,
    id: 3,
    name: "Northwestern Memorial Hospital",
    status: false,
    deleted: false,
    daily: 178,
    weekly: 892,
    monthly: 1960,
    quarterly: 6806,
    internships: 420,
    students: 1310,
    acronym: "NMH",
    zipCode: "60611",
    city: "Chicago",
    slug: "northwestern-memorial",
    country: "United States",
    contact: [
      {
        id: 3,
        name: "Emily Johnson",
        email: "emilyjohnson@mail.com"
      }
    ],
    sector: [
      {
        id: 1,
        name: "Santé"
      }
    ],
    options: [
      {
        id: 3,
        name: "Option 3"
      }
    ]
  }
]

export default institutionsDumpData