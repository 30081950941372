import {useEffect, useState} from "react"

import {Button, Card, Divider, Layout, notification, Spin, Switch, Tag} from "antd"
import Title from "antd/es/typography/Title"
import Search from "antd/es/input/Search"
import BackOfficeTable from "../shared/BackOfficeTable"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faUsers} from "@fortawesome/pro-light-svg-icons"
import TableActionsButtons from "../shared/TableActionsButtons"
import ModalEditUser from "@components/app/users/EditUserModal"
import {deleteUser, getUsers, restoreUser} from "../../../services/userService"
import UserRoleTag from "@components/app/users/UserRoleTag"
import moment from "moment"
import {DATE_TIME_FORMAT_WITHOUT_SECONDS} from "../../../utils/constants"
// @ts-ignore
import NewUserModal from "./NewUserModal.tsx"

const UsersView = () => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [openModalNewUser, setOpenModalNewUser] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [metaData, setMetaData] = useState({})
  const [spinning, setSpinning] = useState(true)
  const [pageTable, setPageTable] = useState(1)
  const [getDeletedData, setGetDeletedData] = useState(false)
  const [filter, setFilter] = useState("")
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    fetchUsers(pageTable, getDeletedData, filter, pageSize)
  }, [pageTable, getDeletedData, filter, pageSize])

  const fetchUsers = (pageTable: number, getDeletedData: boolean, filter: string, pageSize: number): void => {
    getUsers(pageTable, getDeletedData, filter, pageSize).then(async (res) => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      const {data, meta} = await res.json()
      setDataTable(data)
      setMetaData(meta)
      setSpinning(false)
    }).catch((e) => {
      notification.error({
        message: "Error",
        description: `Error: ${e.message}`,
        placement: "bottomRight"
      })
    })
  }

  const handleDelete = (id: number) => {
    deleteUser(id).then(async (res) => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      fetchUsers(pageTable, getDeletedData, filter, pageSize)
      notification.success({
        message: "Success",
        description: `User deleted`,
        placement: "bottomRight"
      })
    }).catch((e) => {
      notification.error({
        message: "Error",
        description: `Error: ${e.message}`,
        placement: "bottomRight"
      })
    })
  }

  const handleRestore = (id: number) => {
    restoreUser(id).then(async (res) => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      fetchUsers(pageTable, getDeletedData, filter, pageSize)
      notification.success({
        message: "Success",
        description: `User restored`,
        placement: "bottomRight"
      })
    }).catch((e) => {
      notification.error({
        message: "Error",
        description: `Error: ${e.message}`,
        placement: "bottomRight"
      })
    })
  }

  const handleCancel = () => {
    setSelectedRow(null)
  }

  const handlePageChange = (page: number) => {
    setPageTable(page)
    setSpinning(true)
  }

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize)
    setSpinning(true)
  }

  const handleGetDeletedData = (checked: boolean) => {
    setPageTable(1)
    setGetDeletedData(checked)
    setSpinning(true)
  }

  const handleFilter = (value: string) => {
    setFilter(value)
    setSpinning(true)
    setPageTable(1)
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "lastname",
      key: "lastname"
    },
    {
      title: "Firstname",
      dataIndex: "firstname",
      key: "firstname"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username"
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
      render: (roles: any) => (<UserRoleTag role={roles}/>)
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      key: "deleted",
      render: (deleted: boolean) => (deleted ? (<Tag color={"error"}>Deleted</Tag>) : (
        <Tag color={"success"}>Active</Tag>))
    },
    {
      title: "Last login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      render: (lastLogin: any) => (lastLogin ? (
        <span>{moment(lastLogin).format(DATE_TIME_FORMAT_WITHOUT_SECONDS)}</span>) : (
        <span style={{color: "grey", fontStyle: "italic"}}>Never</span>))
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <TableActionsButtons
          onDeleted={() => {
            handleDelete(record.id)
          }}
          onRestore={() => {
            handleRestore(record.id)
          }}
          deleted={record.deleted}
          onEditClick={() => setSelectedRow(record)}
          items={undefined}/>)
    }
  ]

  const dataSource: any = dataTable.map((user: any) => {
      return {
        key: user.id,
        id: user.id,
        lastname: user.lastname,
        firstname: user.firstname,
        email: user.email,
        username: user.username,
        roles: user.roles[0],
        deleted: user.deleted,
        language: user.language,
        phone: user.phone,
        lastLogin: user.lastLogin,
        createdAt: user.createdAt,
        createdBy: user.createdBy,
        updatedAt: user.updatedAt,
        updatedBy: user.updatedBy
      }
    }
  )

  return (
    <Layout className="contentLayout">

      <ModalEditUser
        data={selectedRow}
        onClose={handleCancel}
        onSuccess={() => fetchUsers(pageTable, getDeletedData, filter, pageSize)}
      />

      <NewUserModal
        open={openModalNewUser}
        setOpen={setOpenModalNewUser}
        onSuccess={() => fetchUsers(pageTable, getDeletedData, filter, pageSize)}
      />

      <Card className="contentCard">
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Title className="pageTitleText" style={{color: "rgb(77,77,77)"}}>
            <FontAwesomeIcon icon={faUsers} style={{marginRight: 10}}/>
            Users
          </Title>
          <Button onClick={() => {
            setOpenModalNewUser(true)
          }} shape="round" className="success">
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}}/>
            Create new user
          </Button>
        </div>
        <Divider/>

        <Card style={{margin: 5}}>
          <div className="centeredSpaceBetween">
            <div>
              <span style={{fontSize: 13, margin: 10}}>Show deleted users : </span>
              <Switch
                checkedChildren="True"
                unCheckedChildren="False"
                checked={getDeletedData}
                onChange={handleGetDeletedData}
              />
            </div>
            <div>
              <Search placeholder="Search user" onSearch={(value: string) => {
                handleFilter(value.toString())
              }} style={{width: 200}}/>
            </div>
          </div>
          <Divider/>
          <Spin spinning={spinning}>
            <BackOfficeTable
              columns={columns}
              dataSource={dataSource}
              meta={metaData}
              onChangePage={handlePageChange}
              onChangePageSize={handlePageSizeChange}
            />
          </Spin>
        </Card>

      </Card>
    </Layout>
  )
}

export default UsersView