export default class School {
  id?: number
  name!: string
  abbreviation?: string
  address?: string
  zipcode?: string
  city?: string
  country?: string
  phone?: string
  email?: string
  status?: boolean
  deleted?: boolean
  daily?: number
  weekly?: number
  monthly?: number
  quarterly?: number
  internships?: number
  students?: number
  sections?: []
}