import {Button, Layout, Result} from "antd"
import {useNavigate} from "react-router"

const Error = () => {
  const navigate = useNavigate()

  return (
    <Layout style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => {
          navigate(-1)
        }}>Back</Button>}
      />
    </Layout>
  )
}
export default Error