import Navbar from "@components/Navbar"
import {Navigate} from "react-router-dom"

const App = () => {
  if (!localStorage.getItem("token") || localStorage.getItem("token") === "undefined") {
    return (
      <Navigate to="/login"/>
    )
  }
  const token = localStorage.getItem("token")
  const validDateToken = new Date(JSON.parse(atob(token!.split(".")[1])).exp * 1000)
  const currentDate = new Date()
  if (validDateToken < currentDate) {
    localStorage.removeItem("token")
    return (
      <Navigate to="/login"/>
    )
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "row"
    }} className="App">
      <Navbar/>
    </div>
  )
}

export default App
