import {useEffect, useState} from "react"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye} from "@fortawesome/pro-light-svg-icons"
import {Button, Card, Divider, Form, Input, Modal, notification, Select, Spin} from "antd"
import User from "../../../models/User"
import {
  DATE_TIME_FORMAT_WITHOUT_SECONDS,
  ROLE_ADMIN,
  ROLE_COORDINATOR,
  ROLE_ENCODER,
  ROLE_HOSPITAL_ADMIN,
  ROLE_NURSE,
  ROLE_STUDENT,
  ROLE_SUPERVISOR,
  ROLE_VALIDATOR
} from "../../../utils/constants"
import moment from "moment"
import {updateUser} from "../../../services/userService"

interface CProps {
  data: User | null,
  onClose: () => void,
  onSuccess: () => void
}

const ModalEditSchool = ({data, onClose, onSuccess}: CProps) => {
  const [user, setUser] = useState(new User())
  const [spinning, setSpinning] = useState(false)

  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data])

  const handleEdit = (value: User) => {
    setSpinning(true)
    updateUser(user).then(async r => {
      if (!r.ok) {
        throw new Error(`${r.status}: ${r.statusText}`)
      }
      notification.success({
        message: "Success",
        description: `User: ${user.firstname} ${user.lastname} updated`,
        placement: "bottomRight"
      })
      onSuccess()
    }).catch(e => {
      notification.error({
        message: "Error",
        description: e.message,
        placement: "bottomRight"
      })
    }).finally(() => {
      setSpinning(false)
      onClose()
    })
  }

  return (
    <Modal
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon" style={{backgroundColor: "#A32CCF", margin: 10, marginLeft: 15}}>
            <FontAwesomeIcon color="white" size="xl" icon={faEye} style={{margin: 10}}/>
          </div>
          <span>Edit this user: </span>
          {data && <span style={{color: "grey"}}>&nbsp;{data.lastname} {data.firstname} <span
              style={{fontStyle: "italic", fontSize: "75%"}}>{data.id}</span></span>}
        </div>
      )}
      onCancel={onClose}
      open={!!data}
      footer={null}
      destroyOnClose
    >
      <Card className="opalBackground">
        <Spin spinning={spinning}>
          <Form onFinish={handleEdit}>
            <div style={{display: "flex"}}>
              <Form.Item
                label="Firstname"
                style={{marginRight: 10}}
                name="firstname"
                rules={[{required: true, message: "Please input a firstname!"}]}
                initialValue={user.firstname}
              >
                <Input onChange={(e) => setUser({...user, firstname: e.target.value})}/>
              </Form.Item>
              <Form.Item
                label="Lastname"
                name="lastname"
                rules={[{required: true, message: "Please input a lastname!"}]}
                initialValue={user.lastname}
              >
                <Input onChange={(e) => setUser({...user, lastname: e.target.value})}/>
              </Form.Item>
            </div>
            <Form.Item
              label="Email"
              name="email"
              rules={[{type: "email", required: true, message: "Please input a valid email!"}]}
              initialValue={user.email}
            >
              <Input onChange={(e) => setUser({...user, email: e.target.value})}/>
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              rules={[{required: true, message: "Please input a username!"}]}
              initialValue={user.username}
            >
              <Input onChange={(e) => setUser({...user, username: e.target.value})}/>
            </Form.Item>
            <Form.Item
              label="Roles"
              name="role"
              initialValue={user.roles}
            >
              <Select onChange={(e) => setUser({...user, roles: e})}>
                <Select.Option value={ROLE_STUDENT}>Student</Select.Option>
                <Select.Option value={ROLE_ADMIN}>Admin</Select.Option>
                <Select.Option value={ROLE_COORDINATOR}>Coordinator</Select.Option>
                <Select.Option value={ROLE_ENCODER}>Encoder</Select.Option>
                <Select.Option value={ROLE_SUPERVISOR}>Supervisor</Select.Option>
                <Select.Option value={ROLE_VALIDATOR}>Validator</Select.Option>
                <Select.Option value={ROLE_HOSPITAL_ADMIN}>Hospital admin</Select.Option>
                <Select.Option value={ROLE_NURSE}>Nurse</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Language"
              name="language"
              initialValue={user.language}
            >
              <Select onChange={(e) => setUser({...user, language: e})}>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="fr">French</Select.Option>
                <Select.Option value="nl">Dutch</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              initialValue={user.phone}
            >
              <Input onChange={(e) => setUser({...user, phone: e.target.value})}/>
            </Form.Item>
            <Divider/>
            <h3>User informations :</h3>
            <ul>
              <li>Created at: &nbsp;
                {
                  user.createdAt ?
                    moment(user.createdAt).format(DATE_TIME_FORMAT_WITHOUT_SECONDS) :
                    <span className="unused"> Never</span>
                }
              </li>
              <li>Updated at: &nbsp;
                {
                  user.updatedAt ?
                    moment(user.updatedAt).format(DATE_TIME_FORMAT_WITHOUT_SECONDS) :
                    <span className="unused"> Never</span>
                }
              </li>
              <li>Created by: &nbsp;
                {
                  user.createdBy ?
                    user.createdBy.username :
                    <span className="unused">No one</span>
                }
              </li>
              <li>Updated by: &nbsp;
                {
                  user.updatedBy ?
                    user.updatedBy.username :
                    <span className="unused">No one</span>
                }
              </li>
              <li>Last login: &nbsp;
                {
                  user.lastLogin ?
                    moment(user.lastLogin).format(DATE_TIME_FORMAT_WITHOUT_SECONDS) :
                    <span className="unused"> Never</span>
                }
              </li>
            </ul>
            <div style={{display: "flex", justifyContent: "end"}}>
              <Button style={{marginTop: 15, marginRight: 15}} onClick={onClose}>
                Cancel
              </Button>
              <Button style={{backgroundColor: "#A32CCF", marginTop: 15}} type="primary"
                      htmlType="submit">
                Edit
              </Button>
            </div>
          </Form>
        </Spin>
      </Card>
    </Modal>
  )
}

export default ModalEditSchool

