import ReactDOM from "react-dom/client"

import Error from "@components/Error"
import "./index.less"
import i18next from "i18next"
import HttpBackend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import "dayjs/locale/fr"
// Fontawesome
import {library} from "@fortawesome/fontawesome-svg-core"
import {fal} from "@fortawesome/pro-light-svg-icons"
import App from "./App"
import routes from "./routes"
import LoginView from "@components/LoginView"

// Dayjs config
dayjs.extend(localizedFormat)
dayjs.locale("fr")

// i18n config
i18next
.use(HttpBackend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  fallbackLng: "fr",
  debug: true,
  load: "languageOnly"
})

library.add(fal)

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginView/>
  },
  {
    path: "/",
    element: <App/>,
    errorElement: <Error/>,
    children: routes
  }
])

// Root creation
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router}/>
)
