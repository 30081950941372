import {useEffect, useState} from "react"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye} from "@fortawesome/pro-light-svg-icons"
import {Card, Input, Modal, Switch} from "antd"
import School from "../../../models/School"

interface CProps {
  data: School | null,
  onClose: () => void,
  onEdit: () => void
}

const EditSchoolModal = ({data, onClose, onEdit}: CProps) => {

  const [school, setSchool] = useState(new School())

  useEffect(() => {
    if (data) {
      setSchool(data)
    }
  }, [data])

  const handleClose = () => {
    setSchool(new School())
    onClose()
  }

  return (
    <Modal
      title={(
        <div className="centeredTextWithIcon">
          <div className="squareIcon" style={{backgroundColor: "#A32CCF", margin: 10, marginLeft: 15}}>
            <FontAwesomeIcon color="white" size="xl" icon={faEye} style={{margin: 10}}/>
          </div>
          {data && <h2 style={{color: "#232323"}}>&nbsp {data.name} ({data.id})</h2>}
        </div>
      )}
      open={!!data}
      onOk={onEdit}
      onCancel={handleClose}
      okText="Edit"
      okButtonProps={{style: {backgroundColor: "#A32CCF", marginTop: 15}}}
    >
      <Card className="opalBackground">
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          alignItems: "center"
        }}>
          <div style={{flexDirection: "column"}}>
            <label>Name : </label>
            <Input
              style={{width: "90%"}}
              value={school.name}
              onChange={e => setSchool({...school, name: e.target.value})}
            />
          </div>
          <div style={{flexDirection: "row"}}>
            <label>Managed : </label>
            <Switch
              checked={school.status}
              onChange={e => setSchool({...school, status: e.valueOf()})}
            />
          </div>
        </div>
        <label>Abbreviation : </label>
        <Input
          value={school.abbreviation}
          onChange={e => setSchool({...school, abbreviation: e.target.value})}
        />
        <label>Address : </label>
        <Input
          value={school.address}
          onChange={e => setSchool({...school, address: e.target.value})}
        />
        <label>Zipcode : </label>
        <Input
          value={school.zipcode}
          onChange={e => setSchool({...school, zipcode: e.target.value})}
        />
        <label>City : </label>
        <Input
          value={school.city}
          onChange={e => setSchool({...school, city: e.target.value})}
        />
        <label>Country : </label>
        <Input
          value={school.country}
          onChange={e => setSchool({...school, country: e.target.value})}
        />
        <label>Phone : </label>
        <Input
          value={school.phone}
          onChange={e => setSchool({...school, phone: e.target.value})}
        />
        <label>Email : </label>
        <Input
          value={school.email}
          onChange={e => setSchool({...school, email: e.target.value})}
        />
      </Card>
    </Modal>
  )
}

export default EditSchoolModal

