import {Table} from "antd"

interface CProps {
  dataSource: any
  columns: any
  meta?: {
    pages?: {
      page: number
      next: number
      last: number
      per_page: number
      total_pages: number
      total: number
    }
  }
  onChangePage?: (page: number) => void
  onChangePageSize?: (pageSize: number) => void
}

const BackOfficeTable = ({dataSource, columns, meta = {}, onChangePage, onChangePageSize}: CProps) => {
  if (!Array.isArray(dataSource)) {
    throw new Error("Invalid dataSource: expected an array")
  }

  if (!Array.isArray(columns)) {
    throw new Error("Invalid columns: expected an array")
  }

  return (
    <Table
      size="middle"
      scroll={{x: true}}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: meta.pages?.total,
        current: meta.pages?.page,
        pageSize: meta.pages?.per_page,
        showTotal: (total, range) => `Total: ${total} items`,
        onChange: (page, pageSize) => {
          if (onChangePage) {
            onChangePage(page)
          }
          if (onChangePageSize) {
            onChangePageSize(pageSize)
          }
        },
        showQuickJumper: true,
        pageSizeOptions: ["10", "15", "20", "50", "100"]
      }}
    />
  )
}

export default BackOfficeTable
